import React from 'react';

const Ecommerce = () => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
    className="feather feather-ecommerce"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M3.00999 11.22V15.71C3.00999 20.2 4.80999 22 9.29999 22H14.69C19.18 22 20.98 20.2 20.98 15.71V11.22"
        stroke="currentColor"
        strokeWidth="1.056"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M12 12C13.83 12 15.18 10.51 15 8.68L14.34 2H9.67L9 8.68C8.82 10.51 10.17 12 12 12Z"
        stroke="currentColor"
        strokeWidth="1.056"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M18.31 12C20.33 12 21.81 10.36 21.61 8.35L21.33 5.6C20.97 3 19.97 2 17.35 2H14.3L15 9.01C15.17 10.66 16.66 12 18.31 12Z"
        stroke="currentColor"
        strokeWidth="1.056"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M5.64 12C7.29 12 8.78 10.66 8.94 9.01L9.16 6.8L9.64001 2H6.59C3.97001 2 2.97 3 2.61 5.6L2.34 8.35C2.14 10.36 3.62 12 5.64 12Z"
        stroke="currentColor"
        strokeWidth="1.056"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M12 17C10.33 17 9.5 17.83 9.5 19.5V22H14.5V19.5C14.5 17.83 13.67 17 12 17Z"
        stroke="currentColor"
        strokeWidth="1.056"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
  </svg>
);

export default Ecommerce;
