import { srConfig } from '@config';
import { usePrefersReducedMotion } from '@hooks';
import sr from '@utils/sr';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Skills from './skills';
import Workstation from './workstation';

const StyledAboutSection = styled.section`
  max-width: 1100px;

  .inner {
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;

const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'C++',
    'Java',
    'Python',
    'SQL',
    'HTML',
    'CSS',
    'JavaScript',
    'Node.js',
    'React.js',
    'Express.js',
    'Spring Boot',
    'Flutter',
    'Git & GitHub',
    'VS Code',
    'Android Studio',
    'Figma',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
            I am Noor Ul Baseer, a Software Engineering student with a passion for programming and learning modern technologies. Over the years, I’ve worked on languages like C++, Java, and Python, which lead me to develop an interest in creating web and mobile applications. I have been working with modern technologies and frameworks like <b>React.js</b>, <b>Node.js</b>, and <b>Flutter</b>, allowing me to build scalable, efficient, and visually appealing solutions. I have also worked in the UI/UX design field that ensures my applications are functional and user-friendly, providing seamless experience for end-users.
            </p>

            <p>
              Throughout my academic journey, I've gained experience by engaging in internships related to UI/UX design, game programming, and web development. I worked as a Game Programmer at{' '}
              <a href="https://mindstormstudios.com/">Mindstorm Studios</a>{' '}
              , where I developed 2.5D games, while as a Python Developer at{' '}
              <a href="https://www.linkedin.com/company/protectlab/">Protect Lab</a>{' '}
              , allowed me to strengthen my back-end development skills. These experiences have helped me adapt to new challenges, collaborate with teams, and deliver high-quality products.
            </p>

            <p>
            I am dedicated to continuous learning, taking on new challenges, and improving my skills. I am always looking for opportunities to work on exciting projects and collaborate with talented individuals. Here are a few technologies I’ve been working with recently:
            </p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
      <Skills />
      <Workstation />
    </StyledAboutSection>
  );
};

export default About;
